export const dataTableAllowedExcecuteFields = {
    ActionButtonExecute: () => import("./ActionButtonExecute"),
    AggregateFunctionExecute: () => import("./AggregateFunctionExecute"),
    AuthorizedSignatureExecute: () => import("./AuthorizedSignatureExecute"),
    AutoIncrementExecute: () => import("./AutoIncrementExecute"),
    CheckBoxGroupExecute: () => import("./CheckBoxGroupExecute"),
    CheckBoxExecute: () => import("./CheckBoxExecute"),
    CurrencyExecute: () => import("./CurrencyExecute"),
    CurrencyTypesExecute: () => import("./CurrencyTypesExecute"),
    DIVExecute: () => import("./DIVExecute"),
    DateTimeRangeExecute: () => import("./DateTimeRangeExecute"),
    DateTimeExecute: () => import("./DateTimeExecute"),
    DateRangeExecute: () => import("./DateRangeExecute"),
    DateExecute: () => import("./DateExecute"),
    ESignatureExecute: () => import("./ESignatureExecute"),
    EntityVariableExecute: () => import("./EntityVariableExecute"),
    EntityExecute: () => import("./EntityExecute"),
    FileExecute: () => import("./FileExecute"),
    FormulaExecute: () => import("./FormulaExecute"),
    GlobalVariableExecute: () => import("./GlobalVariableExecute"),
    HorizontalLineExecute: () => import("./HorizontalLineExecute"),
    HtmlExecute: () => import("./HtmlExecute"),
    ImageExecute: () => import("./ImageExecute"),
    ListExecute: () => import("./ListExecute"),
    MultiLineTextExecute: () => import("./MultiLineTextExecute"),
    MultiSelectExecute: () => import("./MultiSelectExecute"),
    NumberExecute: () => import("./NumberExecute"),
    ParagraphExecute: () => import("./ParagraphExecute"),
    PhoneCountryCodeExecute: () => import("./PhoneCountryCodeExecute"),
    RadioExecute: () => import("./RadioExecute"),
    RadioButtonGroupExecute: () => import("./RadioButtonGroupExecute"),
    SelectExecute: () => import("./SelectExecute"),
    SingleLineContentExecute: () => import("./SingleLineContentExecute"),
    SingleLineTextExecute: () => import("./SingleLineTextExecute"),

    TimeExecute: () => import("./TimeExecute"),
    VideoExecute: () => import("./VideoExecute"),
    YesOrNoExecute: () => import("./YesOrNoExecute"),
    WeekDaysExecute: () => import("./WeekDaysExecute"),
    TimeRangeExecute: () => import("./TimeRangeExecute"),
    StarRatingExecute: () => import("./StarRatingExecute"),
    ConcatenateExecute: () => import("./ConcatenateExecute"),
    EntityTableExecute: () => import("./EntityTableExecute"),
    PaymentView: () => import("./PaymentExecute"),
    PayVariableExecute: () => import("./PayVariableExecute"),
    PaymentExecute: () => import("./PaymentExecute"),
    IconExecute: () => import("./IconExecute"),
    RandomTextExecute: () => import("./RandomTextExecute"),
    IntegrationExecute: () => import("./IntegrationExecute"),
    IntegrationVariableExecute: () => import("./IntegrationVariableExecute")
}
